<template>
  <div class="dashboard-admin d-flex flex-column">
    <div class="dashboard-admin-content content">
      <div class="container grid-content px-0">
        <div
          class="item-menu"
          v-for="(value, index) in listFunction"
          :key="index"
        >
          <h4 class="text-center font-weight-bold">{{ value.title }}</h4>
          <div class="action-item">
            <div v-for="(item, ind) in value.value" :key="ind">
              <router-link
                class="d-flex"
                :to="{ name: item.routeName, params: item.params }"
              >
                <img :src="item.icon" alt="" class="my-auto" />
                <p class="ml-2 my-auto">{{ item.text }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Constants } from "../../utils/constants";
import pageCreateMenu from "@/assets/img/page_creat.png";
import contentCreateMenu from "@/assets/img/content_create.png";
import fixedPageMenu from "@/assets/img/fixedpage.png";
import iconDefault from "@/assets/img/icon-sale.png";

export default {
  name: "DashboardAdmin",
  data() {
    return {
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeOwner: Constants.USER_TYPE_DEFAULT["owner"],
      typeCDEA: Constants.USER_TYPE_DEFAULT["cdea"],
      listFunction: [
        {
          title: "コンテンツ",
          value: [
            {
              icon: pageCreateMenu,
              text: "コンテンツ作成",
              routeName: "create page",
            },
            {
              icon: contentCreateMenu,
              text: "コンテンツ公開設定",
              routeName: "Content Change",
            },
          ],
        },
        // {
        //   title: "トリガー機能",
        //   value: [
        //     {
        //       icon: iconDefault,
        //       text: "トリガー新規作成",
        //       routeName: "Create Trigger",
        //       roleOwner: true,
        //     },
        //   ],
        // },
        // {
        //   title: "タイミング系",
        //   value: [
        //     {
        //       icon: iconDefault,
        //       text: "ポップアップ新規作成",
        //       routeName: "Create Timing Popup",
        //       roleOwner: true,
        //     },
        //     {
        //       icon: menu22,
        //       text: "メール新規作成",
        //       routeName: "Create TimingMail",
        //       roleOwner: true,
        //     },
        //   ],
        // },
        // {
        //   title: "アンケート",
        //   value: [
        //     {
        //       icon: iconDefault,
        //       text: "アンケート新規作成",
        //       routeName: "create survey",
        //     },
        //   ],
        // },
        {
          title: "単発ページ",
          value: [
            {
              icon: fixedPageMenu,
              text: "単発ページ作成",
              routeName: "createFixedPage",
            },
          ],
        },
        {
          title: "オファー",
          value: [
            {
              icon: iconDefault,
              text: "オファー作成",
              routeName: "Content Sale Create",
              params: {
                shopId: this.$route.params.shopId
                  ? this.$route.params.shopId
                  : "",
              },
            },
          ],
        },
      ],
    };
  },
  created() {
    let shopId = this.$route.params.shopId;
    if (!shopId) {
      this.listFunction.forEach((obj) => {
        obj.value.forEach((e) => {
          if (e.routeName != null) {
            e.routeName += " domain";
          }
        });
      });
    }
  },
};
</script>
